@import './default';

// Default colors
// @import './custom/colors';

// $enable-rounded: false;

// Dependency styling
@import '~bootstrap/scss/bootstrap';
// @import '~@fortawesome/fontawesome-free/scss/fontawesome';
// @import '~@fortawesome/fontawesome-free/scss/solid';
// @import '~@fortawesome/fontawesome-free/scss/regular';
// @import '~@fortawesome/fontawesome-free/scss/brands';
// @import '~react-toastify/scss/main';
@import '~plyr/dist/plyr.css';

// Custom styling
@import './custom';
